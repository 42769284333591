import config from 'utils/siteConfig';

export const sections = [
  {
    title: "Quand consulter un sexologue ?",
    body: "Il est nécessaire de consulter un sexologue pour n’importe quel souci concernant sa santé sexuelle qui persiste de manière récurrente. Nous recommandons de consulter plus vite si les problèmes deviennent une source de tourment pour l’individu ou pour le couple. Les sexologues sont les meilleurs interlocuteurs pour traiter les problèmes de santé intimes qu’ils soient physiques ou psychologiques. Il ne faut pas hésiter à prendre rendez-vous avec un professionnel diplômé si vous avez la moindre question."
  },
  {
    title: "Comment se déroule une consultation avec un sexologue ?",
    body: "Une consultation avec un sexologue sera similaire à l’expérience d’une consultation avec un médecin ou psychologue. La fonction primaire du sexologue sera de vous mettre en confiance pour exprimer les troubles sexuels que vous rencontrez tout en respectant votre intimité. Le sexologue a pour mission d’identifier le problème et de le classifier pour déterminer s’il est de l’ordre physiologique, psychologique ou encore dû à une simple ignorance sexuelle. Si le problème est physiologique, le sexologue pourra prescrire des médicaments pour y remédier, s’il est psychologique, la solution sera d’ordre thérapeutique, d’une durée indéterminée dépendant de l’amplitude du problème."
  },
  {
    title: "Est-ce qu'un sexologue est remboursé ?",
    body: "Les consultations auprès de médecins sexologues titulaires d’un DIU (diplôme universitaire et interuniversitaire) sont prises en charge en partie par la sécurité sociale, mais les dépassements d’honoraire sont communs et non pris en charge. Ces médecins sexologues composent 70% de la totalité des sexologues en France, les 30% restant sont des psychologues, conseillers conjugaux ou thérapeutes. Ils n’ont donc pas de diplômes de médecine et travaillent avec leurs clients sur des bases psychologiques plutôt que médicales, ce qui fait que les consultations auprès de ces professionnels ne sont pas remboursées par la Sécurité Sociale. Dans les deux cas, certaines mutuelles ou complémentaires santé prennent en charge une partie de la consultation, que ce soit en cabinet ou en ligne sur Charles.co."
  },
  {
    title: "Quelle est la formation pour devenir sexologue ?",
    body: "Le métier de sexologue fait partie du domaine de la santé, cependant il n’existe pas de formation officielle pour devenir sexologue. Il existe plusieurs options de parcours pour y parvenir : la première est de passer par une formation de psychologue (maîtrise de psychologie) pour ensuite se spécialiser auprès d’établissements privés dans la sexologie. La deuxième option est de faire des études de médecine générale, les médecins diplômés pourront ensuite passer par un DIU de sexologie. Les deux parcours requièrent un niveau d’études élevé ce qui assure donc la fiabilité de la profession de sexologue."
  },
  {
    title: "À quoi sert un sexologue ?",
    body: "La fonction du sexologue est de régler les problèmes touchant à la sexualité. Il a pour but d’écouter ses patients sans être intrusif envers leur intimité, pour essayer de comprendre le(s) problème(s) qu’ils rencontrent pour ensuite y trouver la solution. Le sexologue peut accompagner ses patients de manière thérapeutique si le problème en question n’est pas physiologique. Le sexologue peut tout aussi avoir des consultations avec des couples si les patients pensent que ça aidera à résoudre leur problème de manière plus simple. Le sexologue a aussi une fonction médicale, si ses patients souffrent de maladies ou non, mais qu’ils rencontrent des problèmes sexuels physiques, le sexologue peut avoir recours à la prescription de divers médicaments qui aideront les patients."
  },
  {
    title: "Les sexologues sont-ils médecins ?",
    body: "Les sexologues peuvent suivre deux parcours avant de se spécialiser dans la sexologie, soit ils peuvent faire des études de médecine, ce qui est le cas de 70% des sexologues en France. Dans ce cas, il s’agit de médecins diplômés pouvant prescrire des médicaments, ausculter et peuvent tout autant s’occuper de l’aspect psychologique. D’autre part, 30% des sexologues s’occupent uniquement de l’aspect relationnel de la sexualité de leurs clients. Ils peuvent être psychologues, conseillers conjugaux ou encore thérapeutes et ne peuvent pas prescrire de médicaments, ou s’occuper de l’aspect mécanique de la sexualité."
  },
  {
    title: "Pourquoi consulter un sexologue plutôt qu’un médecin généraliste ?",
    body: "Une grande partie des sexologues en France ont suivi un parcours de médecine générale pour ensuite se spécialiser dans la sexologie. Les personnes ayant rencontré des problèmes en rapport avec leur santé sexuelle peuvent consulter leur médecin généraliste pour y remédier. Cependant, la valeur ajoutée d’un sexologue est son expertise dans le domaine, le sexologue peut-être moins dérangeant pour aborder et parler de problèmes intimes étant donné qu’il est spécialisé pour traiter ce genre de problème à travers sa profession. Le sexologue apporte aussi un aspect thérapeutique et psychologique qu’un médecin généraliste n’a pas forcément, et pourra donc apporter un support psychologique continu qui peut s’avérer très important dans l’évolution de la sexualité des patients."
  },
  {
    title: "Combien coûte-t-il de consulter un sexologue ?",
    body: `Le prix d’une consultation varie entre 50 et 100 euros pour une session de 45 minutes dépendant du lieu où le sexologue pratique sa profession, étant plus cher dans les grandes villes comme Paris que dans les milieux ruraux. Les consultations peuvent se passer dans des hôpitaux, dans quel cas il faut souvent attendre plusieurs semaines pour prendre rendez-vous, ou alors elles peuvent aussi se passer dans des cabinets de sexologie. À titre comparatif, une consultation en ligne sur Charles.co coûte ${config.consultationPrice} euros pour échanger avec un médecin sexologue diplômé et bénéficier de l’expertise des plus experts.`
  },
];
