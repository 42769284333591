import React, { useState } from 'react';
import Image from '../utils/Image';
import './styles.sass';
import cx from 'classnames';
import T from 'types/index';
import {trackAccordionDeployed} from "utils/segment";

type Props = {
  data: T.Standard.AccordionItem[]
  version?: string
}

const MyAccordion = ({ data, version }: Props) => {
  const [openedIndex, setOpenedIndex] = useState<number>(-1);

  const handleItemClick = (index: number) => {
    trackAccordionDeployed(data[index].title);
    const newIndex = (openedIndex === index) ? -1 : index;
    setOpenedIndex(newIndex);
  };

  const whiteVersion = (version === 'white');
  const svgFilename = whiteVersion ? 'new-images/white-tag.svg' : 'new-images/tag.svg';
  const svgClassName = whiteVersion ? 'accordion-category-tag' : 'accordion-category-tag-black';
  return (
    <div className={cx('my-accordion-component', { 'text-white': version === 'white' })}>

      {data.map((accordionItem, i) => (
        <div
          key={i}
          className={cx('accordion-div', { expanded: openedIndex === i })}
          onClick={() => handleItemClick(i)}
        >
          <div className="accordion-category-title">
            <h3 className={cx('h4', { 'text-white': version === 'white' })}>{accordionItem.title}</h3>
            <Image filename={svgFilename} className={svgClassName} alt={whiteVersion ? `white-tag-${i}` : `fleche-haut-picto-${i}`}
            title={whiteVersion ? `white tag ${i}` : `fleche haut picto ${i}`} />
          </div>
          <div className="accordion-category-text">
            {accordionItem.text}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyAccordion;
