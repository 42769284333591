import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Section from 'components/common/Section';
import MyCarousel, { airtableVideosRenderer} from 'components/common/MyCarousel';
import MyAccordion from 'components/common/MyAccordion';
import Image from 'components/common/utils/Image';
import { isEmpty, isNil } from 'utils/lodashExtracts';
import Cta from 'components/common/Cta';
import { Link } from 'gatsby';
import './styles.sass'
import { Charles, Standard } from 'types/index';

type Props = {
  start_slug: string
  faq_title: string
  faq_items: Charles.ItemRecord[]
  videos: Charles.YoutubeVideoRecord[]
  cta: any
}
const IndexPage = (props: Props) => {

  const faqTitle = props.faq_title || "FAQ"
  const displayVideoCaroussel = !isEmpty(props.videos)
  const accordionData : Standard.AccordionItem[] = props.faq_items.map((item: Charles.ItemRecord) => {
    return {
      title: isNil(item.data.title) ? '' : item.data.title.childMarkdownRemark.rawMarkdownBody,
      text: isNil(item.data.text) ? '' : item.data.text.childMarkdownRemark.rawMarkdownBody
  }})
  return (
    <Section className="pathology-faq-section">
      <div id="faq-anchor" className="pathology-faq-anchor pathology-anchor" />
      <Container>
        <Row className="pathology-faq-row">
          <Col xs={12} className="pr-xl-0">
            <h2>{faqTitle}</h2>
            <div className="faq-description">
              Nos spécialistes répondent à toutes vos questions.
            </div>
            {displayVideoCaroussel
              && (
                <div className="d-flex flex-column align-items-center mb-0 pathology-faq-carousel-wrapper">
                  <MyCarousel<Charles.YoutubeVideoRecord>
                    data={props.videos}
                    chunkOnMobile={true}
                    scrollGap={2}
                    prefix="faq"
                    renderItem={airtableVideosRenderer}
                  />
                </div>
              )}
          </Col>
        </Row>
        <Row className="d-none d-xl-block">
          <Col md={12}>
            <div className="div-as-hr pathology-div-as-hr" />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs={12} xl={7}>
            <MyAccordion data={accordionData} />
          </Col>
          <Col xs={12} md={6} xl={5} className="ml-auto mr-auto m-xl-0">
            <div className="charles-card faq-pathology-charles-card">
              <div className="faq-pathology-card-title">
                Vous ne trouvez pas la réponse à votre question ?
              </div>
              <Link to="/faq/" className="faq-pathology-link">
                <Image
                  filename="new-images/blue-book.svg"
                  alt="livre-bleu-pictoo"
                  title="livre bleu picto"
                />
                <div>FAQ complète</div>
              </Link>
              <a href="tel:0186651733" className="faq-pathology-link">
                <Image
                  filename="new-images/blue-phone.svg"
                  alt="mobile-bleu-picto"
                  title="mobile bleu picto"
                />
                <div>01 86 65 17 33</div>
              </a>
              <a
                href="mailto:contact@charles.co"
                className="faq-pathology-link"
              >
                <Image
                  filename="new-images/blue-mail.svg"
                  alt="mail-bleu-picto"
                  title="mail bleu picto"
                />
                <div>contact@charles.co</div>
              </a>
              {props.cta || <Cta
                text="Consultez un spécialiste"
                path={props.start_slug}
                className="new-primary-cta"
              />}
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
};

export default IndexPage;
