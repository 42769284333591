import React from "react";
import CharlesMeta from "components/common/meta/CharlesMeta";
import StructuredWebPage from "components/common/meta/StructuredWebPage";
import Segment from "utils/segment";
import { Layout } from "components/common";
import Section from "components/common/Section";
import "components/AnnuaireSexo/styles.sass";
import Image from "components/common/utils/Image";
import { sections } from "components/AnnuaireSexo/data";
import { Container } from "react-bootstrap";
import { graphql, Link, navigate } from "gatsby";
import FooterCities from "components/common/FooterCities";
import { citySlug, departmentSlug } from "../templates/Annuary/utils/slugs";
import use50MostSearchedCities from "hooks/use50MostSearchedCities";
import FooterDepartments from "components/common/FooterDepartments";
import useAirtableDepartments from "hooks/useAirtableDepartments";
import StructuredFAQ from "components/common/meta/StructuredFAQ";
import Breadcrumb, { annuaryBreadcrumbs } from "templates/Blog/common/Breadcrumb";
import FaqPathology from "templates/Pathologies/common/FaqPathology";
import { markdownFromString } from "components/common/utils/AirtableMarkdown";

const AnnuaryPage = ({ data }) => {
  function goToDepartment() {
    const { value } = document.getElementById("department-input");
    const code = value.substr(0, 3).replace(" ", "").replace("-", "");
    let foundDepartmentSlug = "404";
    data.allAirtableDepartments.nodes.forEach((item) => {
      if (item.data.code === code) {
        foundDepartmentSlug = item.data.slug;
      }
    });
    navigate(departmentSlug(foundDepartmentSlug));
  }
  const faqItems = sections.map((section) => ({
    data: {
      title: markdownFromString(section.title),
      text: markdownFromString(section.body),
    },
  }))
  const title = "Annuaire des sexologues"
  const description = "Trouvez un médecin sexologue près de chez vous. Téléconsultez un médecin français diplômé en sexologie maintenant depuis chez vous sur Charles.co"
  return (
    <Layout showLinksInFooter={false} showM6Banner>
      <CharlesMeta
        data={{
          title: "Médecins sexologues en France | L'annuaire de Charles",
          description: description
        }}
        segmentCategory={Segment.CATEGORIES.ANNUARY}
      />
      <StructuredWebPage
        title={title}
        description={description}
        sexoWebsiteDescription
      />
      <div className="text-left landing-page">
        <Section className="annuary-therapist-search-section">
          <div className="left-div">
            <div className="text-div">
              <h1>{title}</h1>
              <h2>Des sexologues présents dans toute la france</h2>
              <span>
                (dont <Link to={citySlug("paris", "paris")}>Paris</Link>,{" "}
                <Link to={citySlug("rhone", "lyon")}>Lyon</Link>,{" "}
                <Link to={citySlug("haute-garonne", "toulouse")}>Toulouse</Link>{" "}
                et{" "}
                <Link to={citySlug("bouches-du-rhone", "marseille")}>
                  Marseille
                </Link>
                )
              </span>
            </div>
            <div className="search-div">
              <div className="input-div">
                <input
                  id="department-input"
                  list="department"
                  className="search-input"
                  placeholder="Mon département"
                  autoComplete="off"
                />
                <datalist className="datalist-input" id="department">
                  {data.allAirtableDepartments.nodes.map((department, i) => (
                    <option
                      value={`${department.data.code} - ${department.data.name}`}
                    ></option>
                  ))}
                </datalist>
                <button onClick={goToDepartment} className="annuary-cta">
                  CHERCHER
                </button>
              </div>
            </div>
          </div>
          <div className="right-div">
            <Image
              filename="sex-annuary/annuary-search-illustation-new.svg"
              alt="annuary-search-illustation-new"
              title="annuary search illustation new"
            />
          </div>
        </Section>
        <Section className="annuary-therapist-section">
          <h2>Il existe deux familles de sexologues</h2>
          <div className="annuary-therapist-definition-wrapper">
            <div className="annuary-therapist-definition">
              <Image
                filename="sex-annuary/doctor-therapist.png"
                className="annuary-therapist-image"
                alt="doctor-therapist"
                title="doctor therapist"
              />
              <h3>MEDECIN SEXOLOGUE</h3>
              <span>
                Le médecin sexologue est diplômé de médecine générale, lui
                donnant la possibilité de prescrire des médicaments et
                d’ausculter les patients. Il a ensuite passé un DIU de sexologie
                pour se spécialiser sur les aspects mécaniques et biologique de
                la sexualité.
              </span>
            </div>
            <div className="annuary-therapist-definition">
              <Image
                filename="sex-annuary/psychologist-therapist.png"
                className="annuary-therapist-image"
                alt="psychologist-therapist"
                title="psychologist therapist"
              />
              <h3>PSYCHOLOGUE SEXOLOGUE</h3>
              <span>
                Le psychologue ou thérapeute sexologue a obtenu une maitrise de
                psychologie et a complété sa formation avec une spécialisation
                dans un établissement privé de sexologie. Il sera recommandé
                pour traiter les problèmes sexuels d’ordre psychologie, seul ou
                en couple.
              </span>
            </div>
          </div>
        </Section>
        <FaqPathology
          start_slug="/consulter-sexualite/"
          cta_label="Consulter un sexologue"
          faq_title="Vos questions sur les sexologues"
          faq_items={faqItems}
          videos={[]}
        />
        <StructuredFAQ
          data={sections.map((section) => ({
            question: section.title,
            answer: section.body,
          }))}
        />
        <Section className="landing-section-white">
          <Container className="px-0 pb-5">
            <div  className="mb-3">
              <Breadcrumb links={annuaryBreadcrumbs()} />
            </div>
            <h2 className="h2 text-left mb-4">
              Trouver un sexologue par département
            </h2>
            <FooterDepartments departements={useAirtableDepartments()} />
            <h2 className="h2 text-left mb-4 mt-5">
              Trouver un sexologue par ville
            </h2>
            <FooterCities cities={use50MostSearchedCities()} />
          </Container>
          <div className="pb-5"></div>
        </Section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query StaticDepartmentQuery {
    allAirtableDepartments(
      sort: { fields: data___code_order, order: ASC }
      filter: { data: { active_cities_count: { gt: 0 } } }
    ) {
      nodes {
        data {
          name
          code
          slug
        }
      }
    }
  }
`;

export default AnnuaryPage;
