import { useStaticQuery, graphql } from "gatsby"
import T from "types/index";

export default () : T.Charles.CityRecord[] => {
  const { allAirtableCities: { nodes } } = useStaticQuery(graphql`
    query {
      allAirtableCities(
        sort: { fields: data___search_volume, order: DESC }
        filter: { data: { status: { eq: "active" }}}
        limit: 50
      ) {
        nodes {
          id
          data {
            name
            slug
            departments {
              id
              data {
                slug
              }
            }
          }
        }
      }
    }
  `);
  return nodes
}
